import { graphql } from 'gatsby';
import { arrayOf, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';

import { pictureFluidType } from '../../../types/picture';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import './image.scss';

const bem = new BEMHelper({
  name: 'content-image',
});

export const Image = ({
  id,
  image: [image],
  caption,
  footnotes,
  appearance,
}) => {
  let alignment = appearance;
  if (!caption) {
    // If an image is served without a caption, the image will always be
    // rendered as a fullsize aligned image...
    alignment = 'fullsize';
  }

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <figure {...bem('figure')}>
        <div {...bem('viewport', `alignment-${alignment}`)}>
          <FluidPicture {...image} />
        </div>
        {caption && (
          <figcaption {...bem('caption', `alignment-${alignment}`)}>
            {parse(caption)}
            {footnotes && (
              <footer {...bem('footnotes')}>
                {parse(footnotes)}
              </footer>
            )}
          </figcaption>
        )}
      </figure>
    </Content>
  );
};

Image.propTypes = {
  id: string.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  caption: string,
  footnotes: string,
  appearance: string,
};

Image.defaultProps = {
  caption: undefined,
  footnotes: undefined,
  appearance: 'fullsize',
};

export const fragment = graphql`
  fragment ImageContentFragment on CraftGQL_pageContents_image_BlockType {
    id
    image {
      ...FluidPicture
    }
    caption
    footnotes
    appearance
  }
`;
